import React from "react"
import Logo from "../Logo"

export default function Layout({ children, mode, disableFooter }) {

    return (
      <>
        <main className="container text-center mx-auto text-white pt-40" >
            {children}
            {!disableFooter && <footer className="px-40 sm:px-0 align-center sm:align-left">&copy; 2023 Touchless Inc. Touchless and EXO are trademarks of Touchless Inc. </footer>}
        </main>
      </>
    )
}
  
