import { useLottie } from "lottie-react";
import animation from "../../../static/QPSOoLuP3C.lottie.json";

const style = {
  height: 140,
};

const Example = () => {
  const options = {
    animationData: animation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};

export default Example;