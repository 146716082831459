import * as React from "react"
import Lottie from "lottie-react";
import {Link} from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import LogoAnimation from "../components/LogoAnimation"

const IndexPage = () => {
  return (
    <>
    <Layout mode={false} disableFooter={false} >
      <SEO
        title="Data-first design to create a more accessible world."
        description="Touchless uses data design to make the world more accessible, open, and frictionless. Based in Toronto."
      />
      
      <div className="pt-0 px-60 md:px-80 lg:w-3/4 mx-auto">
      
          <LogoAnimation/>
         <h1 className="text-base font-normal md:text-lg "><span className="font-extrabold">Touchless builds for what's next, to move us all forward, faster.</span> We transform web experiences to grow conversion 4X and leads 3X.</h1>
          
      </div>
      <div className="pt-24 sm:pt-12 md:pt-40 px-64 sm:px-0 md:w-3/4 xl:w-1/2 mx-auto text-md ">
        <div className="mt-24 sm:mt-12 md:mt-24 pb-12 ">
          <h3 className="text-black"><a href="https://www.wyattjohnson.com" rel="nofollow" className="w-3/4 sm:w-2/3 md:w-1/2 mx-auto my-8 bg-white hover:bg-pink duration-200 font-500 transition-all py-4 px-8 rounded-8 text-black block ">See our edge in action</a></h3>
        </div>
        <div className="mt-4 sm:mt-12 md:mt-4 pb-12">
          <h3 className="text-black"><a href="https://www.koons.com" rel="nofollow" className="w-3/4 sm:w-2/3 md:w-1/2 mx-auto my-8 bg-white hover:bg-pink duration-200 font-500 transition-all py-4 px-8 rounded-8 text-black block">Another example</a></h3>
        </div>
        <div className="mt-4 sm:mt-12 md:mt-4 pb-12">
          <h3 className="text-black"><a href="https://www.autogenius.io" className="w-3/4 sm:w-2/3 md:w-1/2 mx-auto my-8 bg-white hover:bg-pink duration-200 transition-all font-500 py-4 px-8 rounded-8 text-black block">Join our Slack community</a></h3>
        </div>
        <div className="mt-4 mb-4 sm:mt-12 md:mt-4 pb-48">
          <h3 className="text-black"><a href="https://calendly.com/touchlesscode/30min" className="w-3/4 sm:w-2/3 md:w-1/2 mx-auto my-8 bg-white hover:bg-pink duration-200 font-500 transition-all py-4 px-8 rounded-8 text-black block">Get in touch</a></h3>
        </div>
      </div>
    </Layout>
    </>
  )
}

export default IndexPage
